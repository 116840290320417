<template>
  <v-navigation-drawer v-model="drawer" app dark temporary>
    <v-container>
      <v-list>
        <v-list-item>
          <v-img
            :src="require('@/assets/logo/logo1.png')"
            class="mr-5 img-center"
            contain
            height="48"
            width="150"
            max-width="150"
            style="cursor:pointer"
            @click="$vuetify.goTo(0)"
          />
        </v-list-item>
        <v-list-item class="mg-top-80" to="/">
          <v-list-item-title>HOME</v-list-item-title>
        </v-list-item>

        <v-list-item to="/ranking">
          <v-list-item-title>RANKING</v-list-item-title>
        </v-list-item>

        <v-list-item to="/test/random">
          <v-list-item-title>RANDOM</v-list-item-title>
        </v-list-item>
        <v-list-item to="/create">
          <v-list-item-title>CREATE TEST</v-list-item-title>
        </v-list-item>
      </v-list>

      <br /><br /><br />
      <br /><br /><br />
      <br /><br /><br />
      <v-row justify="space-around">
        <v-btn small outlined color="primary" to="/sign"
        v-show="logout">
          sign in　
          <v-icon small>mdi-login</v-icon>
        </v-btn>
        <v-btn small outlined color="success" to="/signup"
        v-show="logout">
          sign up　
          <v-icon small>mdi-account-plus</v-icon>
        </v-btn>
        <v-btn small outlined color="success" to="/mypage"
        v-show="isUser">
          MY PAGE　
          <v-icon small>mdi-account-card-details</v-icon>
        </v-btn>
        <v-btn small outlined color="red" @click="btnlogout"
        v-show="isUser">
          LOGOUT　
          <v-icon x-small>mdi-glassdoor</v-icon>
        </v-btn>
        <v-col  v-show="isAdmin">

          <v-btn large outlined color="white" to="/admin">admin</v-btn>
        </v-col>
      </v-row>

      <!-- MENU -->
      <!--       
      <v-list-item
        v-for="(link, i) in links"
        :key="i"
        :to="link.to"
        :href="link.href"
        @click="onClick($event, link)"
      >
        <v-list-item-title v-text="link.text" />
      </v-list-item> -->
    </v-container>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CoreDrawer",
  data(){
    return{
      // hasLocalStorageUserInfo : false  
      logout: true,
      isUser: false,
      isAdmin: false,
    }
  },
  computed: {
    ...mapGetters('common',["links"]),
    // ...mapGetters('user',["hasUserInfo", "isAdmin"]),

    drawer: {
      get() {
        return this.$store.state.common.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
      
  },
  updated(){
    if(sessionStorage.getItem('try-me')){
      this.logout = false
      this.isUser = true
    }
    if(sessionStorage.getItem('admin')){
      this.logout = false
      this.isUser = true
      this.isAdmin = true
    }
  },
  mounted(){
    if(sessionStorage.getItem('try-me')){
      this.logout = false
      this.isUser = true
    }
    if(sessionStorage.getItem('admin')){
      this.logout = false
      this.isUser = true
      this.isAdmin = true
    }
  },
  methods: {
    ...mapMutations('common', ["setDrawer"]),
    ...mapMutations('user',['setUserInfo', 'setAdmin']),
    onClick(e, item) {
      e.stopPropagation();

      if (item.to === "/") {
        this.$vuetify.goTo(0);
        this.setDrawer(false);
        return;
      }

      if (item.to || !item.href) return;

      this.$vuetify.goTo(item.href);
      this.setDrawer(false);
    },
    btnlogout(){
      this.logout = true
      this.isUser = false
      this.isAdmin = false
      
      sessionStorage.removeItem('try-me')
      sessionStorage.removeItem('admin')
      this.$store.dispatch('user','logoutlogout')
      if (this.$router.currentRoute.path === '/ranking') {
        this.$router.go('/ranking')
      } else {
        this.$router.push('/ranking')
      }
    }
  },
};
</script>
